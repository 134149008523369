<template>
  <div class="cts_box">
    <h2 class="sub_ttl"><span>塾生情報</span></h2>
    <div class="student_info flex">
      <div class="stdt_icon"><img v-bind:src="current_user.profile_image_url" alt=""></div>
      <div class="student_detail">
        <div class="inbox flex">
          <div class="detail">
            <dl class="inbox flex">
              <dt>ユーザーネーム（表示名）</dt>
              <dd>{{ current_user.sns_user_name }}</dd>
            </dl>
            <dl class="flex">
              <dt>塾生番号</dt>
              <dd>{{ current_user.number }}</dd>
            </dl>
          </div>
          <div class="achievement">
            <div class="rank_logo" v-bind:class="current_user.dan"></div>
            <!--
              新規塾生 rank0, スタッフ rank_staff
              5級 rank05,4級 rank04,3級 rank03,2級 rank02,1級 rank01
              1段 rank1,2段 rank2,3段 rank3,4段 rank4,5段 rank5
            -->
          </div>
        </div>
        <div class="class"
             v-bind:class="{
                      class01: current_user.sns_rank === 'rank_regular_plus',
                      class02: current_user.sns_rank === 'rank_regular_default' || current_user.sns_rank === 'rank_regular',
                      class03: current_user.sns_rank === 'rank_preparatory',
                      class04: current_user.sns_rank === 'rank_student',
                      class05: current_user.sns_rank === 'rank_corporation'}"
             v-html="rankNameHtml()">

        </div><!--本科プラス class01 / 本科 class02-->
        <p class="alnR"
           v-if="current_user.sns_rank !== 'rank_regular_plus' && current_user.sns_rank !== 'rank_staff' && current_user.sns_rank !== 'rank_corporation'">
          ※一部ご覧頂けないコンテンツがあります</p>
        <p class="alnC" v-if="isUserJoin()">
          <router-link :to="{name: 'UsersCustomerSnsDashboard'}" class="link_txt mt10">マイQRコードをスキャンしてもらってフォローしてもらいましょう</router-link>
        </p>
      </div>
    </div>

    <template v-if="!isUserWithdrawal()">

      <div class="button_wrap" v-if="isUserSleep()">
        <p class="red bold">複眼経済塾に復塾される場合はこちらからお手続きいただけます。<br>
          {{ current_user.sns_user_name }}様は <span class="marker">{{
              current_user.rank_label
            }}・{{ current_user.payment_monthly ? '月額払い' : '年額払い' }} </span>をご利用でしたので、
          <span class="marker">同様のクラス・お支払い方法に復塾</span>となります。<br>
          月初にお支払いいただいた休塾会費との差額を日割り決済させていただきます。</p>
        <a href="javascript:void(0)" @click="cancelSleep()" class="submit basic">差額決済して復塾する</a>
        <p style="color: #666">
          クラス・お支払い方法を変更されたい場合は、一旦現在のクラスで復帰手続き後に、<br>
          すぐにご自身で、復塾後のマイページ内にて変更ができます。
        </p>
      </div>

      <div class="button_wrap" v-if="current_user.withdrawal_request">
        <a href="javascript:void(0)" class="submit light"
           @click="cancelMembershipWithdrawal">退塾申請をキャンセル</a>
        <p class="lead_txt">
          複眼経済塾への退塾申請をキャンセルします。
        </p>
      </div>

      <div class="button_wrap" v-else-if="current_user.change_rank">
        <p class="red bold">現在以下の内容でクラス変更の予約がされています</p>

        <div class="cts_box">
          <div class="class_bf_af flex">
            <div class="inBox flex bf">
              <div class="list flex">
                <span>現在のクラス</span>
                <p>{{ current_user.rank_label }}</p>
              </div>
              <div class="list flex">
                <span>お支払い方法</span>
                <p>{{ current_user.payment_monthly ? '月額払い' : '年額払い' }}</p>
              </div>
            </div>
            <div class="arrow"></div>
            <div class="inBox flex af">
            <span class="bold">
            {{ moment(current_user.change_rank_at, 'YYYY年MM月') }}からのクラス
            </span>
              <div class="new_class">{{ current_user.change_rank }}</div>
            </div>
          </div>
        </div>

        <p>
          <router-link :to="{name: 'UsersCustomerRankChangeCancel'}" class="submit light arrow">クラス変更予約のキャンセル
          </router-link>
        </p>
      </div>

      <div class="button_wrap" v-else-if="current_user.sns_rank !== 'rank_staff' && isUserJoin()">
        <p class="red bold">「クラス変更」や「四季報オンラインの申込」はこちらからお手続きください</p>
        <router-link :to="{name: 'UsersCustomerRankChange'}" class="submit basic arrow">クラスのアップグレード</router-link>
      </div>

      <div class="button_wrap" v-else-if="!isUserJoin() && current_user.no_join_rank_change_enabled">
        <p class="red bold">「クラス変更」や「四季報オンラインの申込」はこちらからお手続きください</p>
        <a href="javascript:void(0)" class="submit basic arrow" @click="modal_open = true">クラスのアップグレード</a>
      </div>

      <div class="button_wrap">
        <p>プロフィール画像とアカウント名を変更する場合はこちらのボタンを押してください</p>
        <router-link :to="{name: 'UsersCustomerProfileEdit'}" class="submit light skeleton">塾生情報の編集</router-link>
      </div>
    </template>

    <div class="modal" v-bind:class="{open: modal_open}">
      <div class="modal_box">
        <div class="header">
          <h4>クラスのアップグレード</h4>
          <div class="close modal_close" v-on:click="modal_open = null"></div>
        </div>
        <div class="inner">
          <ul class="dotted">
            <li>入塾前にクラス変更は1回のみ可能です。</li>
            <li>入塾前のクラス変更には、差額発生がなく、決済はありませんが、入塾後のクラス変更には差額決済が発生します。（アップグレードの場合）</li>
          </ul>
          <div class="btn_wrap">
            <router-link :to="{name: 'UsersCustomerNoJoinRankChange'}" class="submit green">確認</router-link>
          </div>
        </div>
      </div>
      <div class="ovr"></div>
    </div>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  data() {
    return {
      modal_open: false,
    }
  },
  methods: {
    rankNameHtml() {
      const split = this.current_user.rank_name.split('（')
      if (split.length < 2) {
        return this.current_user.rank_name
      } else {
        const rank = split.shift(0)
        return `${rank}<span>（${split.join('（')}`
      }
    },
    cancelSleep() {
      if (!confirm('複眼経済塾の利用を再開します。よろしいですか？')) {
        return
      }
      // ランク変更キャンセル
      this.axios
          .post(`${this.env.api_base_url}users/cancel_seep.json`)
          .then((response) => {
            if (response.data.url) {
              document.location.href = response.data.url
            } else {
              this.$router.push({name: 'UsersCustomerSleepCancelFinish'})
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
          })

    },
    noJoinUpgrade() {
      this.modal_open = true
    },
    cancelMembershipWithdrawal() {
      if (!confirm('退塾の申請をキャンセルしますか？')) {
        return
      }
      this.axios
          .post(`${this.env.api_base_url}users/cancel_membership_withdrawal.json`, {})
          .then(() => {
            // console.log(response.data)
            const message = `退塾の申請をキャンセルしました。\nマイページへ戻ります。`
            alert(message)
            // マイページへ
            //this.$router.push({name: 'UsersCustomerAccount'})
            location.reload()
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
          })
    }
  }
}
</script>